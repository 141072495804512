const basicDataFirstTab = [
  {
    id: 0,
    mainHead: "Basic Assessment Details",
    subHead:
      "Evaluate and improve your environmental, social, and governance performance with our comprehensive assessment tools and customized recommendations.",
  },
  {
    id: 1,
    mainHead: "Stakeholders List",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
];

const basicDataThirdTab = [
  {
    id: 0,
    mainHead: "Self Assessment Survey",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
  {
    id: 1,
    mainHead: "Controversies Survey Questions",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
];

const rmBasicDataFirstTab = [
  {
    id: 0,
    mainHead: "Basic Assessment Details",
    subHead:
      "Evaluate and improve your environmental, social, and governance performance with our comprehensive assessment tools and customized recommendations.",
  },
  {
    id: 1,
    mainHead: "Stakeholders List",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
  {
    id: 2,
    mainHead: "Self Assessment Survey",
    subHead:
      "Identify and prioritize your key stakeholders based on their level of interest and influence, and manage your relationships effectively.",
  },
];
const rmSurveyTabs = [
  {
    id: 0,
    mainHead: "Stakeholders List",
    subHead:
      "Identify potential controversies related to your ESG practices through out targeted survey questions and proactively address them",
  },
];
const esgProfileTab = [
  {
    id: 0,
    mainHead: "ESG Profile",
    subHead:
      "Demonstrate your commitment to sustainable business practices by creating and publishing an ESG declaration outlining your company's values, goals, and performance metrics",
    // "Showcase your ESG performance and progress through a eco-friendly company profile that highlights key metrics, initiatives, and achievements in ESG areas.",
  },
  {
    id: 1,
    mainHead: "ESG KPIs",
    subHead:
      "Monitor and analyze media and public sentiment related to your company's ESG practices and address potential controversies in a timely and effective manner.",
  },
  {
    id: 2,
    mainHead: "Peer Analysis",
    subHead:
      "Benchmark your company's ESG performance against peers in your industry and identify best practices to improve your overall sustainability profile.",
  },
  {
    id: 3,
    mainHead: "Controversies",
    subHead:
      "Monitor and analyze media and public sentiment related to your company's ESG practices and address potential controversies in a timely and effective manner.",
  },
  {
    id: 4,
    mainHead: "Survey Responses",
    subHead:
      "Showcase your ESG performance and progress through a user-friendly company profile that highlights key metrics, initiatives, and achievements",
    // "Demonstrate your commitment to sustainable business practices by creating and publishing an ESG declaration outlining your company's values, goals, and performance metrics",
  },
];

const esgRisk = [
  {
    id: 0,
    mainHead: "ESG Risk Rating",
    subHead:
      "Navigate the corporate landscape with foresight and resilience, addressing potential ESG risks through our comprehensive risk management solutions.",
    // "Showcase your ESG performance and progress through a eco-friendly company profile that highlights key metrics, initiatives, and achievements in ESG areas.",
  },
];

const basicDataSecondTab = [
  {
    id: 0,
    mainHead: "API Connection",
    subHead:
      "Seamlessly integrate ESG assessment data with your existing systems through our flexible and secure API connection",
  },
  {
    id: 1,
    mainHead: "Company Documents",
    subHead:
      "Share your ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
  },
  {
    id: 2,
    mainHead: "Peer Document",
    subHead:
      "Share Peers ESG performance with stakeholders and the public by uploading and publishing comprehensive, accurate, and up-to-date reports.",
  },
  {
    id: 3,
    mainHead: "Survey",
    subHead:
      "Identify potential controversies related to your ESG practices through our targeted survey questions and proactively address them.",
  },
];

export {
  basicDataFirstTab,
  basicDataSecondTab,
  basicDataThirdTab,
  rmBasicDataFirstTab,
  rmSurveyTabs,
  esgProfileTab,
  esgRisk,
};
