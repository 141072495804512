import React, { useState } from "react";
import CompanyProfile from "../esgCompanyProfile/companyProfile";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import funnel from "../../../images/filter.svg";
import NewsArticle from "../esgControversy/newsArticle";
import {
  AppBar,
  Button,
  Icon,
  Menu,
  MenuItem,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPanel/tabPanel";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import InterestMap from "./MapSection";
import ControversyChart from "./ChartSection";
import Controversy from "../esgControversy/controversy";
import ControversyProfile from "../esgControversy/controversyProfile";

function Contoversies(props) {
  const [controversyTabNo, setControversyTabNo] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("None");
  const classes = appbarStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = ({ target }) => {
    target.innerText && setFilter(target.innerText);
    setAnchorEl(null);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Controversies
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          {controversyTabNo === 1 ? (
            <>
              <Button
                onClick={handleClick}
                style={{ color: "#3374b9", textTransform: "none" }}
              >
                {" "}
                <img
                  style={{ marginRight: "5px" }}
                  src={funnel}
                  alt="filter"
                />{" "}
                Filter
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleFilterClose}
              >
                <MenuItem onClick={handleFilterClose}>Positive</MenuItem>
                <MenuItem onClick={handleFilterClose}>Neutral</MenuItem>
                <MenuItem onClick={handleFilterClose}>Negative</MenuItem>
                <MenuItem onClick={handleFilterClose}>None</MenuItem>
              </Menu>
            </>
          ) : (
            <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
              more_vert
            </Icon>
          )}
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={controversyTabNo}
            onChange={(e, newVal) => {
              setControversyTabNo(newVal);
              newVal !== 1 && setFilter("None");
            }}
            // onChange={handleTabChange}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={
                <span className={classes.tabLabel}>Controversy Score</span>
              }
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Articles </span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Insights</span>}
            />

            {/* <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}> Dimension Chat</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Overall</span>}
              /> */}
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={controversyTabNo} index={0}>
        <div
          className="scroll"
          style={{ height: "52vh", marginTop: "10px", paddingBottom: 20 }}
        >
          <ControversyProfile riskEvaluator={props?.riskEvaluator} />
        </div>
      </TabPanel>
      <TabPanel value={controversyTabNo} index={1}>
        <div
          className="scroll"
          style={{ height: "52vh", marginTop: "10px", paddingBottom: 20 }}
        >
          <BlueBackgroundCard heading={"News Articles"} />
          <NewsArticle filter={filter} {...props} />
        </div>
      </TabPanel>
      <TabPanel value={controversyTabNo} index={2}>
        <div className="scroll" style={{ height: "52vh", marginTop: "10px" }}>
          {/* chart section */}
          <BlueBackgroundCard heading={"Controversy over Time"} />
          <ControversyChart />
          {/* map section */}
          <BlueBackgroundCard heading={"Interest by Region"} />
          <InterestMap />
        </div>
      </TabPanel>
    </div>
  );
}

export default Contoversies;
