import React from "react";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import {
  Box,
  Card,
  Grid,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import folder from "../../../images/Folder.png";
import charge from "../../../images/charge.png";
import heart from "../../../images/SelfAssessment.png";
import DonutChart from "react-donut-chart";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { useTopicstyles } from "../selfAssessment/selfAssessmentSurvey";

const negativeControversies = {
  "Anti-competition controversy": 0,
  "Business ethics controversies": 0,
  "Intellectual property controversies": 0,
  "Critical countries controversies": 0,
  "Public health controversies": 0,
  "Tax fraud controversies": 0,
  "Child labour controversies": 0,
  "Human rights controversies": 0,
  "Management compensation controversies count": 0,
  "Consumer controversies": 0,
  "Customer health and safety controversies": 0,
  "Privacy controversies": 0,
  "Product access controversies": 0,
  "Responsible marketing controversies": 0,
  "Responsible R&D controversies": 0,
  "Environmental controversies": 0,
  "Accounting controversies count": 0,
  "Insider dealings controversies": 0,
  "Shareholder rights controversies": 0,
  "Diversity and opportunity controversies": 0,
  "Employee health and safety controversies": 0,
  "Wages or working conditions controversies": 0,
  Strikes: 0,
  "Management compensation controversies": 0,
};

const positiveControversies = {
  "Environmental Sustainability Initiatives": 0,
  "Carbon Emissions Reduction Efforts": 0,
  "Sustainable Sourcing Practices": 0,
  "Waste Reduction and Recycling Programs": 0,
  "Community Engagement and Philanthropy": 0,
  "Employee Diversity and Inclusion Programs": 0,
  "Fair Labor Practices and Employee Benefits": 0,
  "Ethical Supply Chain Management": 0,
  "Transparency and Accountability in Governance": 0,
  "Board Diversity and Independence": 0,
  "Responsible Corporate Citizenship": 0,
  "Stakeholder Engagement and Dialogue": 0,
  "Innovation in Sustainable Technologies": 0,
  "Social Impact Investing and Financing": 0,
  "Climate Change Adaptation Strategies": 0,
  "Water Conservation and Management Practices": 0,
  "Biodiversity Conservation Efforts": 0,
  "Product Safety and Quality Assurance": 0,
  "Health and Wellness Programs for Employees and Customers": 0,
};

function ControversyProfile(props) {
  const { riskEvaluator } = props;
  let classes = useTopicstyles();
  const CircularChart = ({ score, title, color }) => {
    return (
      <Card style={{ margin: "10px 5px", padding: "5px", width: "45%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            width: "100%",
            // marginTop: "10px",
            // marginLeft: "10px",
            padding: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={heart}
              alt="charge"
              style={{ width: "40px", height: "40px" }}
            />

            <DonutChart
              // outerRadius={0.5}
              // innerRadius={0.9}

              className="donutchart-innertext-value"
              height={150}
              width={150}
              legend={false}
              emptyOffset={0.01}
              emptyColor="#F9EEED"
              formatValues={(values, total) => `${score}`}
              colors={[color ? color : "#9ABD8B"]}
              innerRadius={0.7}
              outerRadius={0.8}
              data={[
                {
                  label: "",
                  value: score,
                },
                {
                  label: "",
                  value: 100 - score,
                  isEmpty: true,
                },
              ]}
            />
            {/* <Doughnut data={data} options={options} plugins={plugins} /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color ? color : "#9ABD8B",
                borderRadius: "100%",
                width: "12px",
                height: "12px",
              }}
            />
            <Typography style={{ marginLeft: "10px" }}>{title}</Typography>
          </div>
        </div>
      </Card>
    );
  };
  // console.log(props.riskEvaluator.scores.controversy_categories);
  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <BlueBackgroundCard heading={"Controversy Score"} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card style={{ padding: "5px", width: "100%" }}>
              {/* <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
            ESG Controversy Score
          </Typography> */}
              <Typography
                style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
              >
                Our Controversy Score provides valuable insights into your ESG
                practices, helping you meet stakeholder expectations and work
                towards a more sustainable future.
              </Typography>

              <Box style={{ display: "flex", justifyContent: "space-around" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "space-between",
                    padding: "10px 30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // marginLeft: "21%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />{" "}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      ESG Controversy Score
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img
                      src={charge}
                      alt="charge"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <PercentageDoughnutChart
                      color="#ECA270"
                      emptyColor="#F9EEED"
                      percentage={Math.round(riskEvaluator.scores.esgcScore)}
                      customSize={300}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "space-between",
                    padding: "10px 30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // marginLeft: "21%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />{" "}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Online Sentiment Score
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img
                      src={heart}
                      alt="charge"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <PercentageDoughnutChart
                      color="#ECA270"
                      emptyColor="#F9EEED"
                      percentage={Math.round(
                        riskEvaluator?.scores?.controversy_score || 0
                      )}
                      customSize={300}
                    />
                  </div>
                </div>
              </Box>
            </Card>

            {/* <div style={{ width: "30%", padding: "5px" }}>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Deviation Percentage
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: "24px", padding: "16px 0px" }}
          >
            12%
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            The deviation may indicate a gap between a company's actual ESG
            practices and what is being publicly disclosed, highlighting the
            need for improved transparency and accountability.
          </Typography>
        </div> */}
          </div>
        </Grid>
        <Grid item md={6} style={{ height: "60vh", paddingBottom: "25px" }}>
          <BlueBackgroundCard heading={"Positive Topic"} sideHeading="Count" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            {(props.riskEvaluator.controversyData?.positive ||
              positiveControversies) &&
              Object.entries(
                props.riskEvaluator.controversyData?.positive ??
                  positiveControversies
              ).map(([key, value]) => (
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    padding: 10,
                    backgroundColor: "#FBFBFB",
                    borderBottom: "1px solid #EBF1F8",
                  }}
                >
                  <Grid item md={10}>
                    <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </div>
        </Grid>
        <Grid item md={6} style={{ height: "60vh", paddingBottom: "25px" }}>
          <BlueBackgroundCard heading={"Negative Topic"} sideHeading="Count" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            {(props.riskEvaluator.controversyData?.negative ||
              negativeControversies) &&
              Object.entries(
                props.riskEvaluator.controversyData?.negative ??
                  negativeControversies
              ).map(([key, value]) => (
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    padding: 10,
                    backgroundColor: "#FBFBFB",
                    borderBottom: "1px solid #EBF1F8",
                  }}
                >
                  <Grid item md={10}>
                    <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </div>
        </Grid>
      </Grid>
      <Grid container md={12} spacing={2} style={{ marginTop: 10 }}>
        {riskEvaluator?.scores?.controversy_categories &&
          Object.entries(riskEvaluator?.scores?.controversy_categories)?.map(
            ([topic, score]) => {
              return (
                <>
                  {topic === "Community" && (
                    <Grid item xs={6} md={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Environmental className={classes.size} />
                        <div className={classes.divider}></div>

                        <Typography className={classes.Typography}>
                          Community
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Product responsibility" && (
                    <Grid item xs={6} sm={3} md={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Social className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Product responsibility
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Resource use" && (
                    <Grid item xs={6} sm={3} md={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Governance className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Resource use
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Management" && (
                    <Grid item xs={6} sm={3} md={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Business className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Management
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Human rights" && (
                    <Grid item xs={6} md={4} lg={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Human className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Human rights
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Shareholders" && (
                    <Grid item xs={6} md={4} lg={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Human className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Shareholders
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {topic === "Workforce" && (
                    <Grid item xs={6} md={4} lg={4}>
                      <div className={classes.signleTopicTypeContainer}>
                        <Human className={classes.size} />
                        <div className={classes.divider}></div>
                        <Typography className={classes.Typography}>
                          Workforce
                        </Typography>
                        <Typography className={classes.typonumb}>
                          {score.toFixed(1)}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </>
              );
            }
          )}
      </Grid>
      {/* <Typography style={{ marginTop: 15, fontSize: "15px", fontWeight: 600 }}>
        Controversy Count
      </Typography>
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "13px",
          color: "#15314E",
          paddingBottom: 10,
        }}
      >
        Our Controversy Count provides valuable insights into your ESG
        Controversy practices, helping you meet stakeholder expectations and
        work towards a more sustainable future.
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {props.riskEvaluator.controversyData &&
          Object.entries(props.riskEvaluator.controversyData).map(
            ([key, value]) => (
              <>
                <CircularChart
                  // score={45}
                  score={Math.round(value.toFixed(0))}
                  title={key}
                  color="#4dc9f6"
                />
              </>
            )
          )}
      </div> */}
    </Grid>
  );
}

export default ControversyProfile;
